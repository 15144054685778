import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../assets/sm.jpg';
import img2 from '../assets/seo.jpg';
import img3 from '../assets/google-ads.jpg';
import img4 from '../assets/stack.jpg';
import img7 from '../assets/app.jpg';
import img10 from '../assets/web.jpg';

const PhotoEditorTools = () => {
    const [activeTab, setActiveTab] = useState('Developement & Marketing Services');
    const [hovered, setHovered] = useState(null);

    const aiTools = [
        { 
            name: 'Full Stack Development', 
            image: img4, // Replace with an appropriate image for Full Stack Development if available
            link: 'https://dydaltix.com/book-meet', 
            description: 'Build scalable and robust web applications with comprehensive full stack development, covering both front-end and back-end.'
        },
        { 
            name: 'Web Design', 
            image: img10, // Replace with an appropriate image for Web Design if available
            link: 'https://dydaltix.com/book-meet', 
            description: 'Craft stunning, user-friendly websites with a focus on aesthetics and responsive design for a seamless user experience.'
        },
        { 
            name: 'App Development', 
            image: img7, // Replace with an appropriate image for App Development if available
            link: 'https://dydaltix.com/book-meet', 
            description: 'Create high-performance mobile applications tailored to your needs. Dydaltix delivering exceptional functionality on both iOS and Android.'
        },
        { 
            name: 'Social Media Marketing', 
            image: img1, 
            link: 'https://dydaltix.com/book-meet', 
            description: 'Boost your social media presence, driving engagement and expanding your brand’s reach effortlessly.'
        },
        { 
            name: 'Search Engine Optimization', 
            image: img2, 
            link: 'https://dydaltix.com/book-meet',
            description: 'Optimize your website for search engines, driving higher rankings, increased traffic, and improved visibility across all major platforms.'
        },
        { 
            name: 'Google & Meta Ads', 
            image: img3, 
            link: 'https://dydaltix.com/book-meet', 
            description: 'Maximize your traffic with targeted Google and Meta ads, driving conversions and expanding your audience reach.'
        },
    ];

    const categories = {
        'Developement & Marketing Services': aiTools,
    };

    const toolsToDisplay = categories['Developement & Marketing Services'];

    return (
        <div className="py-8 text-center bg-gradient-to-b from-white to-green-100">
            <h2 className="text-3xl md:text-4xl font-bold mb-4 md:mb-6">
                Discover More About <span className="text-blue-500">Services</span>
            </h2>
            <p className="text-gray-600 mb-6 md:mb-10">
                Transform your digital journey with Dydaltix! Discover top-tier services and expert-led learning to drive success and growth!
            </p>

            <div className="flex flex-wrap justify-center mb-6 md:mb-10">
                {Object.keys(categories).map(category => (
                    <button
                        key={category}
                        className={`py-2 px-4 md:py-2 md:px-6 mx-2 mb-2 md:mb-0 rounded-full text-sm md:text-lg font-semibold transition-all duration-300 ${activeTab === category ? 'bg-black text-white' : 'bg-white border-2 border-black text-blue-500 hover:bg-blue-400 hover:text-white'}`}
                        onClick={() => setActiveTab(category)}
                    >
                        {category}
                    </button>
                ))}
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-10 max-w-6xl mx-auto px-4 md:px-8">
                {toolsToDisplay.map((tool, index) => (
                    <div
                        key={index}
                        className="relative bg-white shadow-lg rounded-lg overflow-hidden w-full sm:max-w-xs flex flex-col justify-between mx-auto transform transition-transform duration-300 hover:scale-105"
                        onMouseEnter={() => setHovered(index)}
                        onMouseLeave={() => setHovered(null)}
                    >
                        <div>
                            <div className="overflow-hidden">
                                {tool.video ? (
                                    <video
                                        className="w-full h-48 md:h-64 object-cover transform transition-transform duration-300 hover:scale-105"
                                        autoPlay
                                        loop
                                        muted
                                        preload="metadata"
                                        playsInline
                                        controlsList="nodownload"
                                        onContextMenu={(e) => e.preventDefault()}
                                    >
                                        <source src={tool.video} type="video/webm" />
                                        Your browser does not support the video tag.
                                    </video>
                                ) : (
                                    <img
                                        src={tool.image}
                                        alt={tool.name}
                                        className={`w-full ${tool.name === 'Face Cutout' ? 'h-48 md:h-64' : 'h-50 md:h-66'} object-cover transform transition-transform duration-300 hover:scale-105`}
                                    />
                                )}
                            </div>
                            <div className="p-4 flex-grow">
                                <h3 className="text-lg md:text-xl font-semibold mb-2">{tool.name}</h3>
                                <p className="text-gray-600 mb-4 text-sm md:text-base">{tool.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Center the button below the grid */}
            <div className="flex justify-center mt-8">
                <Link to="/meet">
                    <button className="bg-black text-white py-3 px-6 rounded-full text-lg font-semibold hover:bg-blue-400">
                        Book A Meeting
                    </button>
                </Link>
            </div>
        </div>
    );
};

export default PhotoEditorTools;
