import React from 'react';
import { Link } from 'react-router-dom';
import img from '../assets/ppp1.jpg';  // Replace with your actual image path
import styles from './Ppp.module.css';  // Import the CSS module

const Ppp = () => {
    return (
        <div className="py-8 bg-gradient-to-b from-white to-green-100 flex flex-col md:flex-row items-center">
            {/* Text Section */}
            <div className="md:w-1/2 p-4 text-center md:text-left">
                <h2 className={`text-4xl md:text-5xl font-bold mb-4 ${styles.animateText}`}>
                    <span className="text-blue-600">E-commerce Design</span> to elevate your business on Shopify, eBay, and more.
                </h2>
                <p className="text-gray-600 text-lg mb-6">
                    Get professionally designed e-commerce stores tailored for platforms like Shopify, eBay, and Amazon. Whether you're launching a new online business or revamping an existing one, we provide custom solutions that ensure a seamless shopping experience for your customers.
                </p>
            </div>

            {/* Image Section */}
            <div className="md:w-1/2 p-4 mt-8 md:mt-0">
                <img src={img} alt="E-commerce Design" className="w-full h-auto object-cover" />
            </div>
        </div>
    );
};

export default Ppp;
