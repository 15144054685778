import React from 'react';
import { FaFacebookF, FaInstagram, FaYoutube, FaTiktok } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';

export default function Footer() {
    return (
        <footer className="bg-black text-white py-10">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap justify-between items-start">
                    
                    {/* Animated Logo Text */}
                    <div className={`${styles.logoContainer} w-full md:w-1/4 mb-8 md:mb-0`}>
                        <div className={styles.logoText}>
                            Dydaltix
                        </div>
                        <div className="flex space-x-4 mt-4">
                            <a href="#" className={`${styles.iconHover} text-white`}><FaFacebookF size={20} /></a>
                            <a href="#" className={`${styles.iconHover} text-white`}><FaInstagram size={20} /></a>
                            <a href="#" className={`${styles.iconHover} text-white`}><FaYoutube size={20} /></a>
                            <a href="#" className={`${styles.iconHover} text-white`}><FaTiktok size={20} /></a>
                        </div>
                        <div className="mt-4">
                            <label htmlFor="language" className="text-gray-400">Language:</label>
                            <select id="language" className={`${styles.languageSelect} bg-black text-white ml-2 border-none outline-none`}>
                                <option value="english">English</option>
                            </select>
                        </div>
                    </div>

                    {/* Service Links */}
                    <div className={`${styles.fadeIn} w-full md:w-1/4 mb-8 md:mb-0`}>
                        <h4 className={`${styles.sectionHeader} text-xl font-bold mb-4`}>Our Services</h4>
                        <ul className="text-lg">
                            <li><Link to="/web-development" className={`${styles.linkAnimation} text-gray-400`}>Web Development</Link></li>
                            <li><Link to="/app-development" className={`${styles.linkAnimation} text-gray-400`}>App Development</Link></li>
                            <li><Link to="/shopify-store" className={`${styles.linkAnimation} text-gray-400`}>Shopify Store Setup</Link></li>
                            <li><Link to="/digital-marketing" className={`${styles.linkAnimation} text-gray-400`}>Digital Marketing</Link></li>
                            <li><Link to="/seo-services" className={`${styles.linkAnimation} text-gray-400`}>SEO Services</Link></li>
                        </ul>
                    </div>

                    {/* Support Links */}
                    <div className={`${styles.fadeIn} w-full md:w-1/4 mb-8 md:mb-0`}>
                        <h4 className={`${styles.sectionHeader} text-xl font-bold mb-4`}>Support</h4>
                        <ul className="text-lg">
                            <li><Link to="/faq" className={`${styles.linkAnimation} text-gray-400`}>FAQ</Link></li>
                            <li><Link to="/contact" className={`${styles.linkAnimation} text-gray-400`}>Contact Us</Link></li>
                            <li><Link to="/blog" className={`${styles.linkAnimation} text-gray-400`}>Blog</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="mt-10 text-center text-gray-400 text-lg">
                    <p>Copyright © 2024 Dydaltix. All Rights Reserved.</p>
                    <div className="flex justify-center space-x-4 mt-2">
                        <Link to="/terms" className={`${styles.linkAnimation} text-gray-400`}>Terms</Link>
                        <Link to="/privacy" className={`${styles.linkAnimation} text-gray-400`}>Privacy</Link>
                        <Link to="/cookies-policy" className={`${styles.linkAnimation} text-gray-400`}>Cookies Policy</Link>
                        <Link to="/license" className={`${styles.linkAnimation} text-gray-400`}>License</Link>
                    </div>
                </div>
            </div>
        </footer>
    );
}
