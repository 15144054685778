import React from 'react';

const Button = () => {
  return (
    <div className="py-8 text-center bg-gradient-to-b from-white to-green-100">
      <div className="flex justify-center">
        <a 
          href="https://facebook.com/dydaltix"
          className="bg-white text-blue-700 border-2 border-black rounded-full py-3 px-8 text-lg font-semibold transition duration-300 ease-in-out hover:bg-gradient-to-r hover:from-black  hover:to-black hover:text-white hover:shadow-lg"
        >
          Join Our Facebook Community
        </a>
      </div>
    </div>
  );
};

export default Button;
