import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './Components/Home'




export default function App() {
  return (
    <div className='w-[100%] relative h-auto'>
      <BrowserRouter basename="/">
        <Routes>
          <Route index element={<Home />} />
         
         
          
       
          
          
       
        </Routes>
      </BrowserRouter>
    </div>
  )
}
