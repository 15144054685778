import React, { useState } from 'react';
import 'tailwindcss/tailwind.css';
import rev1 from '../assets/rev2.webp'; // Update with actual images if needed
import rev2 from '../assets/rev1.webp';
import rev3 from '../assets/rev3.webp';
import rev4 from '../assets/rev4.webp';
import rev5 from '../assets/rev5.webp';

const reviews = [
  {
    name: 'Alice Smith',
    role: 'Full Stack Developer',
    review: 'Working with Dydaltix for my full stack development project was a game changer. Their team understood my requirements perfectly and delivered a robust application. Highly recommended for anyone looking for expert development services!',
    rating: 5,
    img: rev1,
  },
  {
    name: 'Bob Johnson',
    role: 'Web Designer',
    review: 'The website design services offered by Dydaltix are outstanding! They created a visually user-friendly site for my business. The process was seamless, and I couldn’t be happier with the results!',
    rating: 5,
    img: rev2,
  },
  {
    name: 'Charlie Brown',
    role: 'Digital Marketing Expert',
    review: 'Dydaltix has transformed my online presence with their digital marketing strategies. Their team helped boost my website traffic and engagement significantly. They are true professionals in their field!',
    rating: 5,
    img: rev3,
  },
  {
    name: 'Dana Williams',
    role: 'E-commerce Specialist',
    review: 'Thanks to Dydaltix, my e-commerce store looks fantastic! Their expertise in e-commerce design helped me create a seamless shopping experience for my customers. I highly recommend their services!',
    rating: 5,
    img: rev4,
  },
  {
    name: 'Eve Davis',
    role: 'Content Creator',
    review: 'I’ve utilized Dydaltix for multiple projects, including digital marketing and web design. They consistently deliver top-notch work that meets my expectations. Their customer service is also commendable!',
    rating: 5,
    img: rev5,
  },
];

const ReviewCard = ({ name, role, review, rating, img }) => (
  <div className="bg-white p-4 rounded-lg shadow-lg flex-none w-64 mx-2">
    {/* Review Text */}
    <p className="text-gray-800 mb-4">{review}</p>
    {/* Star Rating */}
    <div className="flex items-center mb-4">
      {[...Array(rating)].map((_, i) => (
        <svg
          key={i}
          className="w-5 h-5 text-yellow-500"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 15l-5.5 3 1-6-4-4 6-1L10 0l2.5 7 6 1-4 4 1 6z" />
        </svg>
      ))}
    </div>
    {/* Reviewer Info */}
    <div className="flex items-center">
      <img src={img} alt={name} className="w-12 h-12 rounded-full mr-4" />
      <div className="text-left">
        <h3 className="font-bold text-lg text-gray-800">{name}</h3>
        <p className="text-gray-500">{role}</p>
      </div>
    </div>
  </div>
);

const Reviews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? reviews.length - 1 : prevIndex - 1
    );
  };

  const visibleReviews = [
    reviews[currentIndex],
    reviews[(currentIndex + 1) % reviews.length],
    reviews[(currentIndex + 2) % reviews.length],
  ];

  return (
    <div className="py-8 text-center bg-gradient-to-b from-white to-green-100">
      {/* Section Title */}
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">
        What Do Our Users Say
      </h2>
      <div className="flex justify-center items-center space-x-4">
        {/* Previous Button */}
        
        {/* Review Cards Container */}
        <div className="flex space-x-4 overflow-hidden">
          {visibleReviews.map((review, index) => (
            <ReviewCard key={index} {...review} />
          ))}
        </div>
        
      </div>
    </div>
  );
};

export default Reviews;
