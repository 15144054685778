import React from "react";
import styles from './ImageShowcase.module.css';

const ImageShowcase = () => {
  return (
    <div className="py-8 text-center bg-gradient-to-b from-white to-green-100">
      <div className="flex flex-col md:flex-row items-center justify-center md:justify-between max-w-5xl mx-auto">
        
        {/* Animation Section */}
        <div className="relative w-full md:w-1/2 flex justify-center">
          <div className={styles.animationContainer}>
            {/* Website Container */}
            <div className={`${styles.websiteFrame} ${styles.slideInTop}`}>
              
              {/* 3D Header with Icon */}
              <div className={`${styles.header} ${styles.slideInLeft}`}>
                <div className={styles.headerIcon}></div>
                <div className={styles.navIcon}></div>
                <div className={styles.navIcon}></div>
                <div className={styles.navIcon}></div>
              </div>

              {/* Sidebar and Main Content */}
              <div className={styles.mainSection}>
                <div className={`${styles.sidebar} ${styles.floatSidebar}`}>
                  <div className={styles.sidebarIcon}></div>
                  <div className={styles.sidebarIcon}></div>
                  <div className={styles.sidebarIcon}></div>
                </div>

                <div className={`${styles.contentArea} ${styles.floatContent}`}>
                  <div className={`${styles.contentBlock} ${styles.pulseBlock}`}></div>
                  <div className={`${styles.contentBlock} ${styles.pulseBlock}`}></div>
                  <div className={`${styles.iconButton} ${styles.bounceButton}`}></div>
                </div>
              </div>

              {/* Footer */}
              <div className={`${styles.footer} ${styles.slideInUp}`}>
                <div className={styles.footerIcon}></div>
              </div>
            </div>
          </div>
        </div>

        {/* Text Section */}
        <div className="mt-8 md:mt-0 md:ml-10 w-full md:w-1/2 text-left">
          <h1 className="text-3xl md:text-4xl font-bold text-gray-800 mb-4">
            Dydaltix: Elevate Your Business with Expert <span className="text-blue-600">Website Development</span>
          </h1>
          <p className="text-gray-600 text-lg">
            From design to deployment, Dydaltix crafts immersive, 3D-inspired interactive websites to provide an engaging experience for your customers.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ImageShowcase;
