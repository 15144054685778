import React from 'react';
import 'tailwindcss/tailwind.css';
import { Link } from 'react-router-dom';
import './Banner.css'; // Import the CSS file

const Banner = () => {
    return (
        <div className="bg-gradient-to-r from-blue-200 via-green-100 to-blue-300 py-20 text-center">
            <h1 className="text-5xl mt-8 mb-8 font-bold text-black">
                 <span className="animate-charcter">DYDALTIX</span>  
            </h1>
            <p className="text-black mt-8 mb-8 text-lg">
                Your all-in-one digital solution partner. Start professionally your business with us.
            </p>
            <Link to="/imgenhance">
                <button className="bg-black text-white py-3 px-6 rounded-full text-lg font-semibold hover:bg-blue-400">
                    Book A Meeting
                </button>
            </Link>
        </div>
    );
};

export default Banner;
