import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/Logo.png';

export default function Navbar() {
  const [isAiPoweredToolsMobileOpen, setIsAiPoweredToolsMobileOpen] = useState(false); // New state for mobile dropdown
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <nav className="bg-gradient-to-r from-blue-200 via-green-100 to-blue-200 fixed w-full z-[111] top-0 left-0 border-b border-gray-200 dark:border-gray-600">
        <div className="max-w-screen-xl flex items-center justify-between mx-auto p-4">
          <Link to="/">
            <img src={logo} alt="Logo" className="h-10 w-auto" />
          </Link>

          {/* Hamburger menu for mobile */}
          <button
            className="md:hidden block text-gray-700 hover:text-gray-900 focus:outline-none"
            onClick={toggleMenu}
          >
            {isMenuOpen ? '✖' : '☰'}
          </button>

          {/* Desktop Navigation */}
          <div className={`md:flex justify-center flex-1 space-x-8 hidden md:block`}>
            {/* AI Powered Tools Dropdown for Desktop */}
            <div
              className="relative group hidden md:block" // Only active on desktop
              onMouseEnter={() => setIsAiPoweredToolsMobileOpen(true)}
              onMouseLeave={() => setIsAiPoweredToolsMobileOpen(false)}
            >
              <button className="text-gray-700 hover:text-gray-900 focus:outline-none">
                Digital Services {isAiPoweredToolsMobileOpen ? "▲" : "▼"}
              </button>
              {isAiPoweredToolsMobileOpen && (
                <div className="absolute top-full left-0 mt-0 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[113]">
                  <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <Link to="/imgenhance" className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-100">
                      Full Stack Development
                    </Link>
                    <Link to="/bgrm" className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-100">
                      Website Design
                    </Link>
                    <Link to="/blurbg" className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-100">
                      App Development
                    </Link>
                    <Link to="/retouch" className="block px-4 py-2 text-sm text-gray-700 hover:bg-green-100">
                      Digital Marketing
                    </Link>
                  </div>
                </div>
              )}
            </div>

            {/* AI Editing Tools Link */}
            <Link
              to="https://academy.dydaltix.com/"
              className="text-gray-700 hover:text-gray-900"
              target="_blank"
              rel="noopener noreferrer"
            >
              Academy
            </Link>

            <Link to="https://dydaltix.com/blog" className="text-gray-700 hover:text-gray-900">
              Blog
            </Link>
          </div>
        </div>

        {/* Mobile dropdown content */}
        {isMenuOpen && (
          <div className="md:hidden absolute right-0 top-full mt-2 w-64 max-h-[80vh] overflow-y-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[113]">
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <Link to="/blog" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                Blog
              </Link>

              {/* AI Powered Tools Dropdown for Mobile */}
              <button
                onClick={() => setIsAiPoweredToolsMobileOpen(!isAiPoweredToolsMobileOpen)}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Digital Services {isAiPoweredToolsMobileOpen ? "▲" : "▼"}
              </button>
              {isAiPoweredToolsMobileOpen && (
                <div className="pl-4">
                  <Link to="/imgenhance" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    Full Stack Development
                  </Link>
                  <Link to="/bgrm" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    Website Design
                  </Link>
                  <Link to="/blurbg" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    App Development
                  </Link>
                  <Link to="/retouch" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                    Digital Marketing
                  </Link>
                </div>
              )}

              <Link
                to="https://academy.dydaltix.com/"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                target="_blank"
                rel="noopener noreferrer"
              >
                Academy
              </Link>
            </div>
          </div>
        )}
      </nav>
    </>
  );
}
