import React, { useEffect } from 'react';
import Navbar from './Navbar';
import Header from './Header';

import Banner from './Banner';
import Footer from './Footer';
import DiscoverMoreTools from './DiscoverMoreTools';
import Reviews from './Reviews';
import Button from './Button';

import Ppp from './Ppp';
import ImageShowcase from './ImageShowcase';

export default function Home() {
    // Scroll to top when the component mounts
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Navbar />
            <Banner />
            <DiscoverMoreTools />
            <Ppp />
            <ImageShowcase />
            <Reviews />
            <Button />
          
            <Footer />
        </div>
    );
}
